
*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
   
    --primary: white;
    --secondary: black;
    --variant: ;
    --primary-bg: white;
    --container-width-lg: 80%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    
    --transition: all 400ms ease;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    /* color: var(--primary); */
    color: var(--primary);
    line-height: 1.7;
    overflow-x: hidden;
    /* background: var(--primary-bg); */
    /* background: linear-gradient(
        to bottom,
        aliceblue 0%,
        rgb(151, 171, 192) 50%,
        whitesmoke 50%,
        whitesmoke 100%
    ); */
}

.container {
    width: var(--container-width-sm);
    max-width: 2160px;
    margin: 0 auto;
    /* height: 100vh; */
}

h1, h2, h3, h4, h5 {
    line-height: 1.2;
    color: var(--primary);
}

h1 {
    font-size: 3rem;
}

h1 {
    font-size: 2rem;
}

a {
    color: var(--primary);
    font-size: 1.05rem;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}
/* =========== General Styles =============== */

.research {
    margin-top: 3rem;
    height: 100%;
}

.research__container__top {
    position: relative;
}

.research__container__top img {
    width: 100%;
    object-fit: cover;
    max-height: 80vh;
}

.research__top__content {
    position: absolute;
    width: 40%;
    top: 15%;
    left: 55%;
}

.research__top__content article {
    text-align: left;
}

.research__top__content article h1 {
    color: #32556c;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.research__top__content article p {
    margin-top: 3rem;
    color: #32556c;
    font-size: 1rem;
    line-height: 2.5rem;
    padding-right: 10rem;
}

.research__container__bottom {
    height: fit-content;
    background: whitesmoke;
    display: grid;
    margin-bottom: 3rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.research__bottom__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3rem 0rem;
}

.research__bottom__content > article {
    padding-right: 10rem;
    padding-left: 10rem;
}

.research__bottom__content > article > div > h1 {
    color: rgb(78, 76, 76);
    margin-bottom: 1rem;
    font-size: 1.5rem;
}
.research__bottom__content > article > div > h2 {
    color: rgb(78, 76, 76);
    margin-bottom: 1rem;
    font-size: 1rem;
}
.research__bottom__content > article > div > p {
    color: rgb(78, 76, 76);
    font-size: 0.8rem;
    line-height: 2.2rem;
}

.research__bottom__content > article:nth-child(2) {
    padding-top: 4rem;
}

.research__bottom__content > article:nth-child(2) > div > p {
    color: #d9926c;
}

.research__icons {
    color: #4a7ea1;
    font-size: 40px;
}

.research__icons__top {
    margin-bottom: 1rem;
    color: #32556c;
    font-size: 40px;
}

.research__container__bottom:hover {
    background: white;
    transition: 500ms;
}

@media (max-width: 3440px) {
    .research__top__content {
        top: 25%;
    }
}

@media (max-width: 1920px) {
    .research__top__content {
        top: 15%;
    }

    .research__top__content article p {
        margin-top: 2rem;
    }
}

/* Tablet devices widescreen */
@media (max-width: 1394px) {

    .research__top__content {
        top: 25%;
    }

    .research__bottom__content > article {
        padding-right: 3rem;
        padding-left: 3rem;
    }
    
    .research__top__content article h1 {
        color: #32556c;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
    
    .research__top__content article p {
        margin-top: 1rem;
        font-size: 11px;
        line-height: 30px;
    }

    .research__icons__top {
        margin-bottom: 0.5rem;
    }
    
    .research__bottom__content > article > div > h1 {
        color: #4a7ea1;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }
    .research__bottom__content > article > div > h2 {
        color: #4a7ea1;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
    .research__bottom__content > article > div > p {
        color: rgb(78, 76, 76);
        font-size: 0.7rem;
        line-height: 1.9rem;
    }
   
}

/* Tablet devices portrait */
@media (max-width: 1080px) {

    .research__top__content {
        top: 20%;
    }

    .research__bottom__content > article {
        padding-right: 3rem;
        padding-left: 3rem;
    }
    
    .research__top__content article h1 {
        color: #32556c;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
    
    .research__top__content article p {
        margin-top: 1rem;
        font-size: 11px;
        line-height: 25px;
        padding-right: 4rem;
    }

    .research__icons__top {
        margin-bottom: 0.5rem;
    }
    
    .research__bottom__content > article > div > h1 {
        color: #4a7ea1;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }
    .research__bottom__content > article > div > h2 {
        color: #4a7ea1;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
    .research__bottom__content > article > div > p {
        color: rgb(78, 76, 76);
        font-size: 0.7rem;
        line-height: 1.9rem;
    }

}


@media (max-width: 930px) {
    .research__top__content {
        top: 7%;
        width: 50%;
    }

    .research__bottom__content > article {
        padding-right: 3rem;
        padding-left: 3rem;
    }
    
    .research__top__content article h1 {
        color: #32556c;
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    
    .research__top__content article p {
        margin-top: 1rem;
        font-size: 10px;
        line-height: 20px;
        padding-right: 5rem;
    }

    .research__icons__top {
        margin-bottom: 0.5rem;
        font-size: 30px;
    }

    .research__bottom__content {
        padding: 1rem 0rem;
    }
    
    .research__bottom__content > article > div > h1 {
        color: #4a7ea1;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }
    .research__bottom__content > article > div > h2 {
        color: #4a7ea1;
        margin-bottom: 1rem;
        font-size: 0.7rem;
    }
    .research__bottom__content > article > div > p {
        color: rgb(78, 76, 76);
        font-size: 10px;
        line-height: 25px;
    }
}

@media (max-width: 430px) {
    .research__container__top {
    
        /* background: red; */
        position: relative;
    }
    
    .research__container__top img {
        width: 100%;
        object-fit: cover;
        max-height: 80vh;
    }
    
    .research__top__content {
        position: relative;
        width: 100%;
        left: 0%;
        /* justify-content: center;
        align-items: center; */
        background: whitesmoke;
    }
    
    .research__top__content article {
        text-align: left;
        padding: 1rem 1rem;
        margin-bottom: 2rem;
    }
    
    .research__top__content article h1 {
        color: #32556c;
        font-size: 2rem;
    }
    
    .research__top__content article p {
        margin-top: 1.5rem;
        color: #32556c;
        font-size: 10px;
        line-height: 25px;
        padding-right: 1rem;
    }

    .research__bottom__content {
        display: grid;
        grid-template-columns: 1fr;
        padding: 3rem 0rem;
    }

    .research__bottom__content > article {
        padding-right: 2rem;
        padding-left: 1rem;
    }

    /* .research__bottom__content > article > p {
        color: rgb(78, 76, 76);
        font-size: 10px;
        line-height: 25px;
    } */
}
.form {
    background: linear-gradient(
        to bottom,
        aliceblue 0%,
        rgb(151, 171, 192) 80%,
        white 70%,
        white 100%
    );
    height: 68vh;
}

.form__container {
    background: linear-gradient(
        to bottom right,
        #097897 0%,
        rgb(151, 171, 192) 100%
    );
    height: 100%;
    display: grid;
    grid-template-columns: 30% 58%;
    justify-content: center;
    align-items: center;
}


.form__left__column{
    /* width: 100%; */
    background: blue
}

.form__right__column{
    background: red;
}

/* 




.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);

}

.contact_option_icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}



form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}



@media screen and (max-width: 1024px) {
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}


@media screen and (max-width: 600px) {
    .container.contact_container{
        width: var(--container-width-sm);
    }
} */
.navbar {
    height: 5rem;
    width: 100vw;
    /* background: rgba(174, 204, 216, 0.6); */
    /* background: rgb(3, 137, 182, 0.99); */
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.navbar__container {
    height: 100%;
    /* background: blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 2rem;
}

/* https://www.midjourney.com/app/jobs/8a3bca15-994f-4d9e-bb09-9ab6364ef6df/ */

.navbar__toggle-btn {
    display: none;
}

.navbar__logo {
    /* width: 7rem; */
    /* font-size: 2rem; */
}

.navbar__links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
    height: 100%;
}

.navbar__links__members {
    border: 1px solid white;
    padding: 0.5rem 1.5rem;
    border-radius: 3rem;
}

.navbar__links a:hover {
    border-bottom: 4px solid #fff;
    transition: var(--transition);
}

.navbar__links__members:hover {
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    border-left: 4px solid #fff;
}

/* Media queries small devices*/
@media screen and (max-width: 1583px) {
    .navbar__links > li > a {
        font-size: 14px;
    }
}

@media screen and (max-width: 1366px) {
    .navbar__links > li > a {
        font-size: 12px;
    }
}

@media screen and (max-width: 1080px) {
    .navbar__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .navbar__toggle-btn svg {
        color: var(--primary);
    }

    .navbar__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
    }

    .active-nav, .active-nav::after {
        display: none;
    }

    .navbar__links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.9);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .navbar__links li a {
        background: linear-gradient(90deg, rgb(28, 27, 27, 0.9) 0%, rgb(26, 23, 23, 0.9) 100%);;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 2rem 1rem 3rem;
    }

    .show__navbar {
        display: flex;
    }

    .hide__navbar {
        display: none;
    }

    .navbar__links li:nth-child(2) {
        animation-delay: 200ms;
    }

    .navbar__links li:nth-child(3) {
        animation-delay: 300ms;
    }

    .navbar__links li:nth-child(4) {
        animation-delay: 400ms;
    }

    .navbar__links li:nth-child(5) {
        animation-delay: 500ms;
    }

    .navbar__links li:nth-child(6) {
        animation-delay: 600ms;
    }
    .navbar__links li:nth-child(7) {
        animation-delay: 700ms;
    }
    .navbar__links li:nth-child(8) {
        animation-delay: 800ms;
    }

    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }
}
.main__header {
    background: linear-gradient(
        to bottom,
        aliceblue 0%,
        rgb(151, 171, 192) 80%,
        white 80%,
        white 100%
    );
    overflow: hidden;
    height: 68vh;
}

.main__header__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: url('../../assets/mathslaptopbg.png') center bottom/cover no-repeat;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    justify-content: center;
}

.main__header__content{
    width: 50%;
    padding: 0rem 2rem;
}

.main__header__buttons {
    margin-top: 3rem;
    display: flex;
    gap: 20px;
}

.main__header__ad h1 {
    font-size: 40px;
}

.main__header__ad p {
    padding-right: 12rem;
    margin-top: 3rem;
}

.cta {
    /* background: white; */
    border: 1px solid white;
    padding: 0.5rem 1.5rem;
    border-radius: 3rem;
}

.cta__learn {
    background: transparent;
    color: white;
}

.cta__register {
    color: #40889c;
    background: white;
}

.cta:hover {
    
    padding: 0.5rem 3rem;
    transition: var(--transition);
}


@media (max-width: 3440px) {
    .main__header__content {
        width: 50%;
    }
    .main__header__ad h1 {
        font-size: 60px;
    }

}

@media (max-width: 1920px) {
    .main__header__content {
        width: 54%;
    }

    .main__header__ad h1 {
        font-size: 45px;
    }
}

@media (max-width: 1366px) {
    .main__header__ad h1 {
        font-size: 45px;
    }

    .main__header__ad p {
        padding-right: 6rem;
    }
}

@media (max-width: 1024px) {
    .main__header__ad h1 {
        font-size: 50px;
    }
    .main__header__content {
        width: 70%;
    }

    .main__header__ad p {
        padding-right: 8rem;
    }

}
@media (max-width: 930px) {
    .main__header{
        height: 100vh;
    }

   .main__header__ad h1 {
        font-size: 30px;
        width: 60%;
    }
    .main__header__content {
        width: 100%;
    }

    .main__header__ad p {
        display: none;
    }

    /* .main__header__buttons {
        display: block;
    } */

    .cta {
        margin-top: 1rem;
        font-size: 12px;
        padding: 0.5rem 3.5rem;
    }

}

@media (max-width: 450px) {
    .main__header__ad h1 {
        font-size: 30px;
        width: 70%;
    }
    .main__header__content {
        width: 100%;
    }

    .main__header__ad p {
        display: none;
    }

    .main__header__buttons {
        display: block;
    }

    .cta {
        font-size: 12px;
        display: block;
        padding: 0.5rem 3.5rem;
    }

    .cta__register {
        margin-top: 1rem;
    }

    .cta:hover {
    
        padding: 0.5rem 5rem;
        transition: var(--transition);
    }

}
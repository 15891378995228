.about{
    height: 100%;
}

.about__container__top {
    background: #fff;
    height: 39vh;
    display: grid;
    justify-content: center;
    align-items: center;
}

.about__container__bottom {
    /* margin-top: 1rem; */
    background: whitesmoke;
    /* background: #dfe0e3; */
    height: 58vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-bottom: 1rem;
}

.feature__articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    padding: 2rem;
}

.feature__articles > .article p, .article h1{
    text-align: left;
}


.article h1 {
    font-size: 20px;
    color: #4a7ea1;
    padding-bottom: 1rem;
}

.article p {
    font-size: 12px;
    color: var(--secondary);
}

.about__bottom__right__section {
    overflow: hidden;
}

.about__bottom__right__section img {
    height: 100%;
}

/* need to find a better way */
.about__bottom__left__section {
    padding: 3rem 3rem;
}

.about__bottom__left__section > article > p {
    /* color: #3176a3; */
    color: rgb(78, 76, 76);
    font-size: 13px;
    /* line-height: 27px; */
}

/* need to find a better way */
.about__bottom__left__section > article:nth-child(2) {
    margin-top: 2rem;
}

.about__icons {
    color: #4a7ea1;
    font-size: 40px;
}

.article__first__row, .article__second__row {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.article__second__row {
    margin-top: 1rem;
}

.about__buttons {
    display: flex;
    gap: 20px;
}

.about__buttons > button {
    margin-top: 3rem;
    background: rgb(3, 137, 182);
    border: 1px solid white;
    padding: 0.8rem 1.5rem;
    border-radius: 3rem;
    color: white;
}

.about__buttons > button:hover {
    padding: 0.5rem 3rem;
    color: white;
    transition: var(--transition);
}

.about__bottom__left__section:hover {
    background: white;
    transition: 500ms;
}

/* tablet devices landscape */
@media (max-width: 1366px) {
    .about__container__bottom {
        height: fit-content;
    }
}

@media (max-width: 1155px) {

    .about__container__top{
        height: fit-content;
    }

    .about__container__bottom {
        height: fit-content;
    }

    .feature__articles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 2rem;
    }
}


@media (max-width: 720px) {
    .feature__articles {
        grid-template-columns: 1fr 1fr;
    }

    .about__container__top{
        height: fit-content;
    }

    .about__container__bottom {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .feature__articles {
        grid-template-columns: 1fr;
    }
}